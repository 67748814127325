<template>
  <div class="animated fadeIn">
    <loading :active.sync="isLoading"></loading>
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{ $t('message.reset_password') }}</h1>
          </div>
          <form @submit.prevent="submit">
            <b-row>
              <b-col sm="12">
                <validation-field :label="$t('message.new_password')"
                                  :v="$v.form.passwordNew">
                  <input class="form-control" id="passwordNew"
                         maxlength="50"
                         type="password"
                         v-model="form.passwordNew"
                         v-on:blur="$v.form.passwordNew.$touch()"
                  />
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <validation-field :label="$t('message.label_confirm_password')"
                                  :v="$v.form.passwordConfirm">
                  <input class="form-control" id="passwordConfirm"
                         maxlength="50"
                         type="password"
                         v-model="form.passwordConfirm"
                         v-on:blur="$v.form.passwordConfirm.$touch()"
                  />
                </validation-field>
                <validation-field :v="$v.form.resetPasswordToken">
                  <input id="resetPasswordToken"
                         maxlength="50"
                         type="hidden"
                         v-model="form.resetPasswordToken"
                         v-on:blur="$v.form.resetPasswordToken.$touch()"
                  />
                </validation-field>
                <validation-field :v="$v.form.email">
                  <input id="email"
                         maxlength="50"
                         type="hidden"
                         v-model="form.email"
                         v-on:blur="$v.form.email.$touch()"
                  />
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4" sm="12">
                <b-input-group :class="{ 'error': $v.form.recaptchaVerified.$error }" class="mb-3">
                  <vue-recaptcha
                    :loadRecaptchaScript="true"
                    :sitekey="sitekey"
                    :language="this.$route.params.lang"
                    @expired="onExpired"
                    @verify="onVerify"
                    ref="recaptcha"
                  >
                  </vue-recaptcha>
                  <span class="error" v-if="$v.form.recaptchaVerified.$error">Obbligatorio</span>
                </b-input-group>
              </b-col>
            </b-row>
            <hr class="mb-4">
            <b-row>
              <b-col md="6" sm="12">
                <b-button :class="{invalid:$v.form.$invalid}" block
                          type="submit" class="bg-theme-secondary mb-2">
                  {{$t('message.button_send_text')}}
                </b-button>
              </b-col>
              <b-col md="6" sm="12">
                <b-button block type="button" v-on:click="reset()"
                          variant="secondary">
                  {{$t('message.button_reset_text')}}
                </b-button>
              </b-col>
            </b-row>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import ValidationField from "../components/ui/ValidationField";
  import ApiResolver from '../components/common/ApiResolver';
  import Api from '../components/common/Api';
  import {email, helpers, minLength, required, sameAs} from 'vuelidate/lib/validators'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import VueRecaptcha from 'vue-recaptcha';

  const atLeastOneUppercaseLetter = helpers.regex('alpha', /[A-Z]/);
  const atLeastOneLowercaseLetter = helpers.regex('alpha', /[a-z]/);
  const atLeastOneNumber = helpers.regex('alpha', /[0-9]/);
  const atLeastOneSymbol = helpers.regex('alpha', /(?=.*?[#?!@$%&*_~])/);
  const notSuchSymbols = helpers.regex('alpha', /^[a-zA-Z0-9#?!@$%&*_~]*$/);

  export default {
    name: "ResetPassword",
    props: [
      'query'
    ],
    components: {
      ValidationField,
      Loading,
      VueRecaptcha
    },
    data() {
      return {
        isLoading: false,
        sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
        form: {
          passwordNew: null,
          passwordConfirm: null,
          resetPasswordToken: this.query.resetPasswordToken,
          email: this.query.email,
          recaptchaVerified: false
        },
        error_message: null
      }
    },
    mounted() {
      //console.log(this.query);
    },
    methods: {
      onVerify: function (response) {
        this.form.recaptchaVerified = true
      },
      onExpired: function () {
        //console.log('Expired')
      },
      resetRecaptcha() {
        this.$refs.recaptcha.reset() // Direct call reset method
      },
      submit() {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        this.isLoading = true;
        Api
          .post(ApiResolver.get('RESET_PASSWORD_CONFIRM'), JSON.stringify(this.form))
          .then(response => {
            this.isLoading = false;
            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.reset_password_confirmed')
            });
            /* redirect alla login **/
            this.$router.push({name: 'login'})
          })
          .catch(reason => {
            this.isLoading = false;
            this.error_message = reason.title + ' : ' + reason.detail;
            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: this.error_message
            });
          });
      },
      reset() {
        this.form = {
          passwordNew: null,
          passwordOld: null
        };
        this.$v.form.reset();
      }
    },
    validations: {
      form: {
        passwordNew: {
          required: required,
          atLeastOneUppercaseLetter,
          atLeastOneLowercaseLetter,
          atLeastOneNumber,
          min: minLength(6),
          atLeastOneSymbol,
          notSuchSymbols
        },
        passwordConfirm: {
          required: required,
          sameAs: sameAs('passwordNew')
        },
        resetPasswordToken: {
          required: required
        },
        email: {
          required: required,
          email
        },
        recaptchaVerified: {
          sameAs: sameAs(() => true)
        }
      }
    }
  }
</script>

<style scoped>
  .hasError label {
    color: red;
  }

  .error {
    color: red
  }
</style>
